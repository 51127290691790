import * as React from "react";
import { graphql } from "gatsby";
import { Box, Text, Flex } from "@chakra-ui/react";
import SeoComponent from "../components/SeoComponent";
import Loading from "../components/Loading";

/* SECTIONS */
import Hero from "../sections/Hero";
import CommunityDetails from "../sections/CommunityDetails";
// import WanaSupporterCommunity from '../sections/WanaSupporterCommunity';

type objectType = { [x: string]: any };

function CommunityPage(props) {
  const { data } = props;

  const [sections, setSections] = React.useState<objectType | null>(null);
  const seoImg =
    data.sanityPage.seoImage?.image || (sections as any)?.hero?.heroImg?.bgImage;

  React.useEffect(() => {
    const sectionsObj = {};

    data.sanityPage.sections.map((section, indx) => {
      sectionsObj[section._type] = section;
    });

    setSections(sectionsObj);
  }, []);

  return (
    <Box>
      <SeoComponent
        title={data.sanityPage.seoTitle}
        description={data.sanityPage.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />

      {sections && (
        <Box>
          {/* HERO */}
          <Hero
            header={sections.hero.mainHeader.headerText}
            subheader={sections.hero.accentHeader.headerText}
            heroBgImg={sections.hero.heroImg}
          />

          {/* COMMUNITY DETAILS */}
          <CommunityDetails
            data={sections.communityDetails}
            charity={sections.wanaSupporterOf}
          />

          {/* WANA SUPPORTER OF */}
          {/* <WanaSupporterCommunity data={sections.wanaSupporterOf} /> */}
        </Box>
      )}

      {!sections && (
        <Flex
          w="100%"
          minH="100vh"
          mx="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Flex>
      )}
    </Box>
  );
}

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "community" } }) {
      _id
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ... on SanityHero {
          _key
          _type
          accentHeader {
            headerText
          }
          mainHeader {
            headerText
          }
          heroImg {
            bgImage {
              asset {
                url
                gatsbyImageData(placeholder: NONE, width: 6000)
              }
            }
            brightness
            contrast
          }
        }
        ... on SanityCommunityDetails {
          _key
          _type
          sectionHeader
          communityTitle
          communityDetailsItems {
            itemTitle
            itemDetails
            _rawItemDetailsBlock
            itemImage {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
            itemVideoUrl
            itemMoreDetails {
              _key
              _type
              sectionType
              _rawOneColumnText
              oneColumnImage {
                image {
                  asset {
                    url
                    alt2: originalFilename
                    gatsbyImageData(placeholder: NONE)
                  }
                }
                linkType
                internallLink {
                  route
                }
                externalLink
              }
              _rawTwoColumnsWrapperTextImageTxt
              twoColumnsWrapperTextImageImg {
                image {
                  asset {
                    url
                    alt2: originalFilename
                    gatsbyImageData(placeholder: NONE)
                  }
                }
              }
              twoColumnsWrapperTextAlignmentImageRight
            }
            itemCTA {
              _key
              _type
              _rawTextContent
              bgColor {
                color
              }
              textColor {
                color
              }
              externalLink
              internallLink {
                route
              }
            }
          }
        }
        ... on SanityWanaSupporterOf {
          _key
          _type
          title
          text
          topOrganizations {
            name
            link
            img {
              asset {
                gatsbyImageData
              }
            }
          }
          organizationsList {
            name
            link
          }
        }
      }
    }
    allSanityProductCategory(sort: { fields: _createdAt }) {
      nodes {
        name
        slug {
          current
        }
        subcategories {
          name
          slug {
            current
          }
          category {
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

export default CommunityPage;
